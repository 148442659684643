/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Navigator from './navigator'
import Header from './header'
import './layout.css'

const Layout = ({ children, hideNavigation=false }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>

			<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bulma@0.9.1/css/bulma.min.css" />
        
		    <Header siteTitle={data.site.siteMetadata.title} />
			
			<main>{children}</main>
			
			{!hideNavigation && <Navigator />}
			
			<footer className="footer">
				<div className="content has-text-centered" style={{ letterSpacing: 1 }}>
					<p>EPICTOOLKIT.COM {new Date().getFullYear()} © ALL RIGHTS RESERVED</p>
				</div>
			</footer>

        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
