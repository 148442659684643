export default [
    // {
    //     key: `financial`,
	// 	label: `Financial`,
	// 	href: `/financial/`,
	// 	description: `Free, simple-to-use, online financial tools.`,
    //     children: [
	// 		{
	// 			key: `take-home-pay-calculator`,
	// 			label: `Take-Home Pay Calculator`,
	// 			description: `Calculate your take-home salary with ease.`,
	// 		},
	// 	],
    // },
    {
        key: `math`,
		label: `Mathematics`,
		href: ``,
		description: `Free, simple-to-use, online math tools.`,
        children: [
			{
				key: `percentage-calculator`,
				label: `Percentage Calculator`,
				description: `Calculate percentage values and percent increases/decreases.`,
				href: `/math/percentage-calculator/`
			},
			{
				key: `percent-error-calculator`,
				label: `Percentage Error Calculator`,
				description: `Calculate error percentages in one click.`,
				href: `/math/percent-error-calculator/`
			},
		],
    },
    {
        key: `random`,
		label: `Random`,
		href: ``,
		description: `Tools used as random generators.`,
        children: [
			{
				key: `random-number-generator`,
				label: `Random Number Generator`,
				description: `Generate random integers and floating-point numbers.`,
				href: `/random/number-generator/`
			},
			{
				key: `random-password-generator`,
				label: `Strong Password Generator`,
				href: `/random/password-generator/`,
				description: `An online strong password generator.`,
			},
			{
				key: `random-memorable-password-generator`,
				label: `Memorable Password Generator`,
				href: `/random/memorable-password-generator/`,
				description: `A memorable strong passphrase generator.`,
			},
			{
				key: `random-question-generator`,
				label: `Random Question Generator`,
				href: `/random/question-generator/`,
				description: `An online random question generator.`,
			},
		],
	},
    {
        key: `gambling`,
		label: `Gambling`,
		href: ``,
		description: `Maximize your earnings with these FREE gambling calculators.`,
        children: [
			{
				key: `odds-converter`,
				label: `Odds Converter`,
				description: `Convert odds from and to different formats.`,
				href: `/gambling/odds-converter/`
			},
		],
    },
	{
        key: `dummy`,
		label: `Dummy Generators`,
		href: ``,
		description: `Useful online dummy data generators.`,
		children: [
			{
				key: `lorem-ipsum-generator`,
				label: `Lorem Ipsum Generator`,
				href: `/dummy/lorem-ipsum-generator/`,
				description: `A configurable dummy lorem ipsum text generator.`,
			},
		]
	},
	{
        key: `alcohol`,
		label: `Alcohol Tools`,
		href: ``,
		description: `Simple calculators for home brewers and mixologists.`,
		children: [
			{
				key: `abv-calculator`,
				label: `ABV Calculator`,
				href: `/alcohol/abv-calculator/`,
				description: `A straightforward alcohol by volume calculator.`,
			},
		]
	},
    {
        key: `text`,
		label: `Text`,
		href: ``,
		description: `Simple-to-use online text and string tools.`,
        children: [
			{
				key: `word-counter`,
				label: `Word Counter`,
				href: `/text/word-counter/`,
				description: `Awesome online word counter.`,
			},
			// {
			// 	key: `ngrams`,
			// 	label: `Ngrams`,
			// 	href: `/text/ngrams/`,
			// 	description: `Our amazing ngram tools too help you with text analysis.`,
			// 	children: [
			// 		{
			// 			key: `ngram-generator`,
			// 			label: `Ngram Generator`,
			// 			href: `/text/ngrams/ngram-generator`,
			// 			description: `Generate ngrams from text or a URL.`,
			// 		},
			// 		{
			// 			key: `ngram-analyzer`,
			// 			label: `Ngram Analyzer`,
			// 			href: `/text/ngrams/ngram-analyzer`,
			// 			description: `Analyze the ngrams from text or a URL.`,
			// 		},
			// 	]
			// },
		],
	},
    // {
    //     key: `datetime`,
	// 	label: `Date & Time`,
	// 	href: `/datetime/`,
	// 	description: `Free, simple-to-use, online date and time tools.`,
    //     children: [],
	// },
	// {
    //     key: `conversions`,
	// 	label: `Unit Conversions`,
	// 	href: `/conversions/`,
	// 	description: `Free unit converters.`,
    //     children: [],
    // },
	// {
    //     key: `health-and-lifestyle`,
	// 	label: `Health & Lifestyle`,
	// 	href: `/health/`,
	// 	description: `Awesome tools for your health and lifestyle.`,
    //     children: [],
    // },
	// {
    //     key: `coding`,
	// 	label: `Coding`,
	// 	href: `/coding/`,
	// 	description: `Great instant tools for coders and programmers.`,
    //     children: [
	// 		{
	// 			key: `html`,
	// 			label: `HTML`,
	// 			href: `/html/`,
	// 			description: `Free tools based around HTML.`,
	// 			children: [
	// 				{
	// 					key: `html-minifier`,
	// 					label: `Html Minifier`,
	// 					href: `/html/html-minifier/`,
	// 					description: `Compress and minify your HTML.`,
	// 				},
	// 				{
	// 					key: `html-tag-remover`,
	// 					label: `Html Tag Remover`,
	// 					href: `/html/html-tag-remover/`,
	// 					description: `Remove all or specific HTML tags and attributes.`,
	// 				},
	// 			]
	// 		},
	// 	],
    // },
	// {
    //     key: `miscellaneous`,
	// 	label: `Miscellaneous`,
	// 	href: `/misc/`,
	// 	description: `Useful tools you may need in your everyday life.`,
    //     children: [
	// 	],
    // },
]
