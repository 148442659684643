import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Logo = ({ height=`1em`, width=`1em`, style={ verticalAlign: `bottom` } }) => (
	<svg enableBackground="new 0 0 24 24" style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m2.75 0c-1.519 0-2.75 1.231-2.75 2.75v8.75h11.5v-11.5zm4.5 6.75h-1v1c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1h-1c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1v-1c0-.414.336-.75.75-.75s.75.336.75.75v1h1c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m0 12.5v8.75c0 1.519 1.231 2.75 2.75 2.75h8.75v-11.5zm7.53 6.47c.293.293.293.768 0 1.061-.146.146-.338.219-.53.219s-.384-.073-.53-.22l-.72-.72-.72.72c-.146.146-.338.22-.53.22s-.384-.073-.53-.22c-.293-.293-.293-.768 0-1.061l.72-.72-.72-.72c-.293-.293-.293-.768 0-1.061s.768-.293 1.061 0l.72.72.72-.72c.293-.293.768-.293 1.061 0s.293.768 0 1.061l-.72.72z"/><path d="m21.25 0h-8.75v11.5h11.5v-8.75c0-1.519-1.231-2.75-2.75-2.75zm-.5 6.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m12.5 12.5v11.5h8.75c1.519 0 2.75-1.231 2.75-2.75v-8.75zm8 8h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></svg>
)

const Header = ({ siteTitle }) => (
    <header
        style={{
            background: `#f0f0f0`,
            padding: `2rem`,
        }}
    >
        <div
		 	className="container"
        >
            <h1 style={{ margin: 0, lineHeight: 1, fontSize: `1.2rem`, textAlign: `center` }}>
                <Link
                    to="/"
                    style={{
                        color: `#000`,
                        textDecoration: `none`,
                        textTransform: `uppercase`,
                    }}
                >
                    <Logo /> <span style={{ fontSize: `1em`, fontWeight: `600`, letterSpacing: 1 }}>{siteTitle}</span>
                </Link>
            </h1>
        </div>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
