
import Tree from "./tree"

export const Hierarchy = Tree

export const findItem = (list, matcher) => {

	if (typeof matcher !== `function`)
		throw new Error(`The matcher arguement must be a function.`)

	for (const item of list) {
		
		if (matcher(list) === true)
			return item

		if (Array.isArray(item.children))
			return findItem(item.children, matcher)
		
	}

	return null
}

export default Hierarchy