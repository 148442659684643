import { Link } from 'gatsby'
import React from 'react'
import { Hierarchy, findItem } from '../hierarchy'

import "./navigator.css"

const ItemLink = ({ className, href, children }) =>
    href && href.length > 0 ? (
        <Link className={className} to={href}>{children}</Link>
    ) : (
        <a className={className} style={{ cursor: `text` }}>{children}</a>
    )

const Item = ({ label, href, children, description, depth }) => {

	const hasChildren = Array.isArray(children) && children.length > 0
	const hasDescription = typeof description === `string` && description.length > 0

	return (
		<li className={`navigator-item ${hasChildren ? `has-children` : ``}`}>
			
			<ItemLink className={`depth-${depth}`} href={href}>
				<span>{label}</span>
			</ItemLink>

			{hasDescription && <p className="navigator-description">{description}</p>}
	
			{hasChildren ? (
				<ul className={`nested-list list-depth-${depth+1}`}>
					{children.map(p => (
						<Item {...p} depth={depth+1} />
					))}
				</ul>
			) : null}
	
		</li>
	)
}

const Navigator = () => (
	<section className="section is-small" style={{ backgroundColor: `#fcfcfc` }}>
		<div className="container is-fluid">
			<nav className="navigator-root">
				<ul className="list-depth-0 top-list">
					{Hierarchy.map(p => (
						<Item {...p} depth={0} />
					))}
				</ul>
			</nav>
		</div>
	</section>
)

export default Navigator
